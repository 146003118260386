import React, { useEffect, useState } from "react";
import { Alert, Form } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Checkbox from "../../components/forms/Checkbox";
import TextInput from "../../components/forms/TextInput";
import PasswordInput from "../../components/forms/PasswordInput";
import axios from "../../api/axios";
import useAuth from "../../hooks/useAuth";
import useLocalStorage from "../../hooks/useLocalStorage";

const LOGIN_URL = "/login";

function Login() {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const signup = location.state?.signup;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showActivationMessage, setShowActivationMessage] = useState(false);
  const [err, setErr] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [, setPresistedAuth] = useLocalStorage("auth", "");

  useEffect(() => {
    setErr("");
  }, [email, password]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ email, password }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      const data = response?.data?.result;
      const authorization = data?.authorisation;
      const user = data?.user;
      const accessToken = authorization?.token;
      const authObj = { user, accessToken };
      setAuth(authObj);
      setPresistedAuth(authObj);
      setEmail("");
      setPassword("");
      navigate(from, { replace: true });
    } catch (error) {
      const code = error?.response?.data?.result?.code;
      setShowActivationMessage(code === "INACTIVE_USER");
      setErr(getErrorMessage(code));
    }
  };

  const getErrorMessage = (code) => {
    if (!code) {
      return "Something went wrong, please try again later!";
    }
    switch (code) {
      case "INVALID_CREDENTIALS":
        return "Wrong Email or Password";
      case "INACTIVE_USER":
        return "Your account is inactive. Please contact support to activate your account.";
      case undefined:
        return "Something went wrong, please try again later!";
      default:
        return "Login Failed";
    }
  };
  return (
    <>
      <Form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between align-items-center pb-3">
          <h2 className="fs-4 fw-bold m-0">Login</h2>
          <div>
            Don't have an account?{" "}
            <Link to="/auth/signup" className="fs-6 text-decoration-underline">
              Sign up Now
            </Link>
          </div>
        </div>
        {signup && (
          <Alert variant="success">
            Your account has been created successfully! Please log in to get
            started.
          </Alert>
        )}
        {err && !showActivationMessage && <Alert variant="danger">{err}</Alert>}
        <TextInput
          name="email"
          label="email"
          id="email"
          placeholder="name@email.com"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <PasswordInput
          name="password"
          type="password"
          label="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <div className="actions d-flex gap-3 mb-3">
          <Link to="../forgot-password">Forget password?</Link>
        </div>
        <Button variant="primary" className="w-100 my-3" type="submit">
          Sign In
        </Button>
        <Checkbox
          className="remember-me my-3"
          label="Remember me"
          onChange={() => setRememberMe(!rememberMe)}
          checked={rememberMe}
        />
      </Form>
      <p className="text-center">
        By logging in you agree to Impart Inc.'s{" "}
        <Link to="https://impactpartnersnetwork.com/legal" target="_blank">
          Terms of Service
        </Link>{" "}
        and{" "}
        <Link to="https://impactpartnersnetwork.com/legal" target="_blank">
          Privacy Policy
        </Link>
        .
      </p>
    </>
  );
}

export default Login;
